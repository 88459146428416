import http from '@/plugins/http'

export default class TimeOffInLieu {
  static async offer(params) {
    return await http.post('time-off-in-lieu', params)
  }

  static async update(timeOffInLieu, params) {
    return await http.put('time-off-in-lieu/' + timeOffInLieu.id, params)
  }

  static async notOffer(timeOffInLieu, params) {
    return http.delete('time-off-in-lieu/' + timeOffInLieu.id, { params })
  }

  static async get(params) {
    const { data } = await http.get('time-off-in-lieu', { params })

    return data[0]
  }
}
