<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2
              class="page-title tw-flex tw-items-center"
              data-cy="allowances-title"
            >
              <span>Allowances</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  A leave allowance sets the limit of what an employee is
                  allowed to take.
                  <div>
                    <a
                      class="btn-link tw-font-semibold"
                      target="_blank"
                      href="https://help.leavedates.com/hc/en-us/articles/360002594299-Leave-allowances"
                      >More info</a
                    >
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
          <div>
            <SpinnerButton
              data-cy="add-allowance"
              type="button"
              @click="create"
            >
              Add Allowance
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-scroll-hint class="table-responsive settings-table">
        <table class="ld-table ld-table-clickable" data-cy="allowances-table">
          <thead>
            <tr>
              <th>Allowance</th>
              <th>Description</th>
              <th>Breakdown</th>
              <th style="width: 100px" />
            </tr>
          </thead>
          <tbody>
            <template v-if="allowances.length">
              <tr
                v-for="allowance in sortedAllowances"
                :key="allowance.id"
                @click.stop="edit(allowance)"
              >
                <td>{{ allowance.name }}</td>
                <td v-text="allowance.description" />
                <td data-cy="breakdown" v-text="breakdownsSummary(allowance)" />
                <td>
                  <div class="tw-flex tw-items-center tw-justify-end">
                    <button
                      class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
                      title="Edit"
                      data-cy="edit-allowance"
                      @click.stop="edit(allowance)"
                    >
                      <div class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon
                          name="edit-pencil"
                          class="svg-icon tw-text-gray-700"
                        />
                      </div>
                    </button>
                    <button
                      class="btn btn-icon tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
                      title="Delete"
                      data-cy="delete-allowance"
                      @click.stop="destroy(allowance)"
                    >
                      <div class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon name="trash" class="svg-icon tw-text-white" />
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="4">
                <NoData />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="550"
      name="allowances-form"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="beforeClose"
      @before-open="beforeOpen"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">{{ editing ? 'Edit' : 'New' }} Allowance</p>
          </div>
          <div data-cy="allowance-close">
            <button class="modal-close" @click="hideAllowancesForm">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-py-3">
        <form class="tw-w-full" @submit.prevent="submit">
          <div v-if="!editing" class="tw-px-3">
            <div class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="name">
                  Allowance Name <span class="required-field">&#42;</span>
                </label>
                <input
                  id="name"
                  v-model="form.name"
                  v-focus
                  v-validate="'required|max:50'"
                  data-vv-name="name"
                  data-vv-as="allowance name"
                  data-cy="allowance-name"
                  class="form-control"
                  type="text"
                  autocomplete="off"
                />
                <p
                  v-show="errors.has('name')"
                  data-cy="allowance-error"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('name') }}
                </p>
              </div>
            </div>

            <div class="form-group tw-mb-3">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="description">
                  Allowance Description
                </label>
                <input
                  id="description"
                  v-model="form.description"
                  v-validate="'max:100'"
                  data-vv-name="description"
                  data-vv-as="allowance description"
                  data-cy="allowance-desc"
                  class="form-control"
                  autocomplete="off"
                  type="text"
                />
                <p
                  v-show="errors.has('description')"
                  data-cy="allowance-desc-error"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('description') }}
                </p>
              </div>
            </div>

            <div v-if="!showBreakdowns" class="form-group">
              <div class="tw-w-full tw-px-3">
                <label class="form-label" for="default-amount">
                  Default amount
                </label>
                <div
                  class="tw-w-full tw-flex tw-items-center tw-border tw-border-gray-330 tw-rounded-md"
                >
                  <input
                    id="default-amount"
                    v-model="breakdownDefaultAmount"
                    v-validate="'required|decimal|min_value:0'"
                    data-vv-name="default-amount"
                    data-vv-as="default amount"
                    data-cy="breakdown-default-amount"
                    class="form-control tw-mr-4 tw-w-2/3 tw-border-0"
                    type="number"
                    min="0"
                    step="any"
                    inputmode="decimal"
                    @input="() => errors.clear()"
                    @change="() => errors.clear()"
                  />
                  <div
                    class="tw-w-1/3 tw-py-3 tw-px-4 tw-border-l tw-border-gray-330 tw-bg-gray-200 tw-rounded-r-md"
                  >
                    {{
                      activeCompany.allowance_unit_is_days ? 'Days' : 'Hours'
                    }}
                    per year <span class="required-field">&#42;</span>
                  </div>
                </div>

                <p
                  v-show="
                    errors.has('default-amount') ||
                      errors.first('amount_in_minutes')
                  "
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('default-amount') }}
                  {{ errors.first('amount_in_minutes') }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="showBreakdowns" class="tw-p-3">
            <div class="tw-w-full tw-px-3 tw-mb-6 sm:tw-mb-0">
              <p class="form-label">Summary</p>
              <AllowanceSummary
                v-show="!editAllowanceSummary"
                :allowance="form"
                @show-edit-form="showAllowanceSummaryForm"
              />

              <AllowanceSummaryInput
                v-show="editAllowanceSummary"
                :allowance="form"
                @cancel="hideAllowanceSummaryForm"
              />
            </div>
          </div>

          <div
            v-if="showBreakdowns"
            class="tw-p-3"
            data-cy="allowances-breakdown"
          >
            <div class="tw-w-full tw-px-3 tw-mb-6 sm:tw-mb-0">
              <p class="form-label">Policy</p>

              <AllowanceBreakdownWrapper
                v-for="(item, index) in sortedBreakdowns"
                :ref="'wrapper' + item.id"
                :key="item.id"
                :data-breakdown="item"
                :has-carry-over-allowance="hasCarryOverAllowance"
                :should-hide-edit-breakdown-form="
                  selectedBreakdownId !== item.id || shouldHideEditBreakdownForm
                "
                :breakdown-storing="breakdownStoring"
                :breakdown-deleting="breakdownDeleting"
                :class="{ 'tw-mb-4': index !== breakdowns.length - 1 }"
                data-cy="allowance-breakdown-wrapper"
                @edit-breakdown="editBreakdown"
                @drop-breakdown="dropBreakdown"
                @edit-breakdown-form-open="showEditBreakdown"
                @breakdown-editing="isBreakdownEditing = true"
                @edit-breakdown-form-close="resetEditBreakdownFormStatus"
              />

              <AllowanceBreakdownInput
                v-if="showAddBreakdownForm"
                ref="input"
                :data-breakdown="defaultBreakdown"
                :has-carry-over-allowance="hasCarryOverAllowance"
                :storing="breakdownStoring"
                :deleting="breakdownDeleting"
                data-cy="allowance-breakdown-input"
                @add-breakdown="addBreakdown"
                @cancel="hideAddNewBreakdownForm"
              />
            </div>

            <button
              :disabled="isBreakdownEditing"
              class="btn btn-link tw-px-3 tw-mt-2 tw-text-blue-500 tw-underline"
              type="button"
              data-cy="btn-add-new-policy"
              @click.stop="showAddNewBreakdownForm"
            >
              <span>&#43;</span> Add new policy
            </button>
          </div>

          <div class="tw-pt-5 tw-border-t tw-border-gray-300"></div>

          <div class="tw-px-3">
            <div class="tw-flex tw-flex-wrap tw-mb-2">
              <div class="tw-w-full tw-px-2">
                <div
                  :class="[
                    showBreakdowns ? 'tw-justify-between' : 'tw-justify-end',
                  ]"
                  class="tw-flex tw-items-center"
                >
                  <div v-if="showBreakdowns" class="tw-flex tw-items-center">
                    <button
                      :disabled="loading || isBreakdownEditing"
                      type="button"
                      class="tw-inline-flex tw-items-center tw-text-red-500"
                      data-cy="btn-delete-allowance"
                      @click.stop="destroy(current)"
                    >
                      <SvgIcon
                        name="trash"
                        class="svg-icon tw-w-4 tw-h-4 tw-mr-2"
                      />
                      <span>Delete Allowance</span>
                    </button>
                  </div>
                  <SpinnerButton
                    v-if="!editing"
                    :disabled="!valid || loading"
                    :loading="loading"
                    :spinner-only="true"
                    data-cy="save-allowance"
                    type="submit"
                  >
                    Save Allowance
                  </SpinnerButton>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { debounce, delay, sortBy } from 'lodash-es'
import NoData from '@/components/NoData'
import ValidatesForm from '@/mixins/ValidatesForm'
import AllowancesShortcut from '@/mixins/page-shortcuts/settings/AllowancesShortcuts'
import FormatNumber from '@/mixins/FormatNumbers'
import AllowanceBreakdownWrapper from '@/components/allowance/AllowanceBreakdownWrapper'
import AllowanceBreakdownInput from '@/components/allowance/AllowanceBreakdownInput'
import AllowanceSummary from '@/components/allowance/AllowanceSummary'
import AllowanceSummaryInput from '@/components/allowance/allowanceSummaryInput'
import SpinnerButton from '@/components/SpinnerButton'

const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'Allowances',

  components: {
    AllowanceSummaryInput,
    AllowanceSummary,
    NoData,
    ExtraInfo,
    AllowanceBreakdownInput,
    AllowanceBreakdownWrapper,
    SpinnerButton,
  },

  mixins: [ValidatesForm, AllowancesShortcut, FormatNumber],

  data: () => ({
    form: {
      name: '',
      description: '',
    },
    initialBreakdown: {
      name: 'Annual allowance',
      defaultAmount: null,
      type: 1,
    },
    breakdowns: [],
    editing: false,
    loading: false,
    showBreakdowns: false,
    isBreakdownEditing: false,
    showAddBreakdownForm: false,
    editAllowanceSummary: false,
    shouldHideEditBreakdownForm: false,
    selectedBreakdownId: null,
    carryOverRequestProcessing: false,
    confirmed: false,
    breakdownStoring: false,
    breakdownDeleting: false,
  }),

  computed: {
    ...mapState('allowances', {
      current: 'current',
      allowances: 'list',
    }),

    breakdownDefaultAmount: {
      get() {
        if (this.initialBreakdown.defaultAmount) {
          return this.toFixed(
            parseFloat(this.initialBreakdown.defaultAmount),
            2
          )
        }

        return ''
      },

      set: debounce(function(value) {
        this.initialBreakdown.defaultAmount = value
      }, 200),
    },

    breakdownDefaultAmountInMinutesPerWorkingDay() {
      const defaultAmount = this.initialBreakdown.defaultAmount
        ? this.initialBreakdown.defaultAmount
        : 0

      return (
        parseFloat(defaultAmount) *
        (this.activeCompany.allowance_unit_is_days
          ? this.activeCompany.minutes_per_working_day
          : 60)
      )
    },

    defaultBreakdown() {
      return {
        id: null,
        name: '',
        policy_settings: {
          type: 1,
          expiry_duration: 0,
          expiry_duration_unit: 0,
          allocation_type: 1,
        },
        amount_in_minutes: 0,
        minutes_per_working_day: this.activeCompany.minutes_per_working_day,
        allowance_type_id: this.current.id,
      }
    },

    hasCarryOverAllowance() {
      return (
        this.carryOverRequestProcessing ||
        this.breakdowns.some(breakdown => breakdown.policy_settings.type === 2)
      )
    },

    sortedBreakdowns() {
      return sortBy(this.breakdowns, ['policy_settings.type', 'name'])
    },

    sortedAllowances() {
      return sortBy(this.allowances, 'name')
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.getAllAllowances()
      },
    },
  },

  methods: {
    ...mapActions('allowances', {
      getAllAllowances: 'all',
      storeAllowance: 'store',
      updateAllowance: 'update',
      findAllowance: 'find',
      setCurrent: 'setCurrent',
      storeBreakdown: 'addBreakdown',
      updateBreakdown: 'updateBreakdown',
      removeBreakdown: 'removeBreakdown',
    }),

    async submit() {
      const valid = await this.validate()

      if (!valid) return

      try {
        this.loading = true

        const payload = {
          ...this.form,
          company_id: this.activeCompany.id,
        }

        if (this.editing) {
          await this.updateAllowance({ payload, allowance: this.current })

          this.$modal.hide('allowances-form')
        } else {
          await this.storeAllowance(payload)

          await this.storeBreakdown({
            allowance: this.current,
            payload: {
              name: this.initialBreakdown.name,
              allowance_type_id: this.current.id,
              company_id: this.activeCompany.id,
              minutes_per_working_day: this.activeCompany
                .minutes_per_working_day,
              amount_in_minutes: this
                .breakdownDefaultAmountInMinutesPerWorkingDay,
              type: this.initialBreakdown.type,
            },
          })

          this.breakdowns = [...this.current.breakdown_types]

          this.editing = true
          this.showBreakdowns = true
        }

        this.getAllAllowances()

        this.showAssignAllowanceInProgressMessage()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    getAllowanceAllocateSuccessMessage(actionString) {
      return `${actionString} allowances for employees. We will notify you when it's complete.`
    },

    create() {
      this.reset()

      this.$modal.show('allowances-form')
    },

    async addBreakdown(breakdown) {
      try {
        if (this.isCarryOverBreakdown(breakdown)) {
          this.carryOverRequestProcessing = true
        }

        this.breakdownStoring = true

        await this.storeBreakdown({
          allowance: this.current,
          payload: { ...breakdown, company_id: this.activeCompany.id },
        })

        this.breakdowns = [...this.current.breakdown_types]

        this.hideAddNewBreakdownForm()

        await this.getAllAllowances()

        this.showAssignAllowanceInProgressMessage()
      } catch ({ response }) {
        this.$refs.input.validateFromResponse(response)
      }

      this.breakdownStoring = false

      this.carryOverRequestProcessing = false
    },

    async editBreakdown(breakdown) {
      try {
        if (this.isCarryOverBreakdown(breakdown)) {
          this.carryOverRequestProcessing = true
        }

        this.breakdownStoring = true

        await this.updateBreakdown({
          breakdown,
          payload: { ...breakdown, company_id: this.activeCompany.id },
        })

        this.breakdowns = [...this.current.breakdown_types]

        this.shouldHideEditBreakdownForm = true

        await this.getAllAllowances()

        this.success('The policy has been updated.')
      } catch ({ response }) {
        this.$refs[
          'wrapper' + breakdown.id
        ][0].$refs.input.validateFromResponse(response)
      }

      this.breakdownStoring = false

      this.carryOverRequestProcessing = false
    },

    async dropBreakdown(breakdown) {
      if (
        this.isAmountPerYearPolicy(breakdown) &&
        this.hasOnlyOneAmountPerYearPolicy()
      ) {
        return this.error(
          'You need to keep at least one amount per year allowance policy.'
        )
      }

      const confirmed = await this.confirm(
        'Are you sure you want to delete this policy?',
        'Confirm delete'
      )

      if (!confirmed) return

      this.breakdownDeleting = true

      try {
        await this.removeBreakdown({ breakdown, company: this.activeCompany })

        this.breakdowns = [...this.current.breakdown_types]

        this.shouldHideEditBreakdownForm = true
        this.isBreakdownEditing = false

        await this.getAllAllowances()

        this.success('The policy has been deleted.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.breakdownDeleting = false
    },

    async edit(allowance) {
      try {
        this.editing = true
        this.showBreakdowns = true

        await this.setCurrent(allowance)

        this.form = Object.assign({}, this.current)
        this.breakdowns = [...this.current.breakdown_types]

        this.$modal.show('allowances-form')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async destroy(allowance) {
      try {
        const confirmed = await this.confirm(
          'Are you sure you want to delete this allowance?',
          'Confirm delete'
        )

        if (!confirmed) return

        await this.$http.delete(`allowance-types/${allowance.id}`, {
          data: { company_id: this.activeCompany.id },
        })

        this.$modal.hide('allowances-form')
        this.reset()

        await this.getAllAllowances()

        this.success('Allowance deleted successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async showEditBreakdown(breakdown) {
      this.selectedBreakdownId = breakdown.id
    },

    resetInitialBreakdown() {
      this.initialBreakdown = {
        name: 'Annual allowance',
        defaultAmount: null,
        type: 1,
      }
    },

    reset() {
      this.$nextTick(() => {
        this.form = {
          name: '',
          description: '',
        }
        this.breakdowns = []
        this.resetInitialBreakdown()
        this.editing = false
        this.loading = false
        this.showBreakdowns = false
        this.isBreakdownEditing = false
        this.showAddBreakdownForm = false
        this.editAllowanceSummary = false
        this.shouldHideEditBreakdownForm = false
        ;(this.selectedBreakdownId = null), this.$validator.reset()
        this.errors.clear()
      })
    },

    async hideAllowancesForm() {
      if (!this.isBreakdownEditing) {
        this.confirmed = true
        this.$modal.hide('allowances-form')
        this.reset()

        return
      }

      this.confirmed = await this.confirm(
        'This page contains unsaved changes. Do you still wish to leave the page?',
        'Unsaved Changes'
      )

      if (!this.confirmed) return

      this.$modal.hide('allowances-form')
      this.reset()
    },

    showAddNewBreakdownForm() {
      this.isBreakdownEditing = true

      this.showAddBreakdownForm = true
    },

    hideAddNewBreakdownForm() {
      this.isBreakdownEditing = false

      this.showAddBreakdownForm = false
    },

    showAllowanceSummaryForm() {
      this.editAllowanceSummary = true
    },

    hideAllowanceSummaryForm() {
      this.editAllowanceSummary = false
    },

    resetEditBreakdownFormStatus() {
      this.isBreakdownEditing = false

      delay(() => {
        this.shouldHideEditBreakdownForm = false
      }, 200)
    },

    async beforeClose(event) {
      if (this.confirmed || !this.isBreakdownEditing) return

      event.stop()

      this.confirmed = await this.confirm(
        'This page contains unsaved changes. Do you still wish to leave the page?',
        'Unsaved Changes'
      )

      if (!this.confirmed) return

      this.$modal.hide('allowances-form')
    },

    beforeOpen() {
      this.confirmed = false
    },

    breakdownsSummary(allowance) {
      return sortBy(allowance.breakdown_types, ['policy_settings.type', 'name'])
        .map(item => item.name)
        .join(' + ')
    },

    isCarryOverBreakdown(breakdown) {
      return breakdown.type === 2
    },

    showAssignAllowanceInProgressMessage() {
      this.success(
        this.getAllowanceAllocateSuccessMessage('Assigning'),
        'In progress...'
      )
    },
    isAmountPerYearPolicy(breakdown) {
      return breakdown.type === 1
    },
    hasOnlyOneAmountPerYearPolicy() {
      let amountPerYearPolicyCount = this.breakdowns.filter(
        breakdown => breakdown.policy_settings.type === 1
      ).length

      return amountPerYearPolicyCount === 1
    },
  },
}
</script>
