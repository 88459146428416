<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title">Billing</h2>
          </div>
          <div class="tw-flex tw-flex-wrap">
            <div>
              <SpinnerButton
                v-if="hasStripeId && !isManuallyBilled"
                :loading="loading || loadingPortal"
                :spinner-only="true"
                type="button"
                data-cy="billing-portal"
                :disabled="!hasStripeId"
                @click="redirectToBillingPortal"
              >
                Open billing portal
                <SvgIcon name="link" class="tw-ml-1 tw-w-6 tw-h-6" />
              </SpinnerButton>
            </div>
          </div>
        </div>
        <div v-if="loading" class="vld-parent" style="min-height: 10rem;">
          <Loading loader="dots" :is-full-page="false" :active="loading" />
        </div>
        <p v-if="isManuallyBilled && !loading" class="tw-mt-1 w-text-gray-700">
          Contact support to manage subscription, billing and payment details,
          including invoice history.
        </p>
        <p
          v-if="isSubscriptionActive && !isManuallyBilled && !loading"
          class="tw-mt-1 w-text-gray-700"
        >
          Use the billing portal to manage subscription, billing and payment
          details, including invoice history.
        </p>
        <div v-if="hasPaymentIssue && !loading">
          <p class="tw-mt-1 w-text-gray-700">
            <strong
              >You currently have a payment issue with your
              subscription.</strong
            >
          </p>
          <p class="tw-mt-2 w-text-gray-700">
            Please use the billing portal to update your billing details and
            avoid disruption to your account.
          </p>
        </div>
        <div
          v-if="(!loading && !isSubscribed) || (isCanceled && !isInGracePeriod)"
          class="tw-mt-1 w-text-gray-700"
        >
          <p>
            You are currently on the <strong>free</strong> plan using
            <strong>{{ used }}</strong> of <strong>{{ planLimit }}</strong>
            employees.
          </p>
          <div v-if="isOnFreePlan">
            <p class="tw-mt-6">
              Eager to share the joy of Leave Dates with your entire team?
            </p>
            <p class="tw-mt-6">
              Our premium plan lets you do just that. All the same great
              features but aimed at teams with more than five employees.
            </p>
          </div>
        </div>
        <div v-if="!loading && (isCanceled || isPreviousCustomer)">
          <div v-if="isInGracePeriod">
            <p class="tw-mt-6">
              Your subscription has been <strong>cancelled</strong>.
            </p>
            <p class="tw-mt-6">
              To access your previous invoices or to renew your subscription use
              the billing portal link above.
            </p>
          </div>
          <div v-else>
            <p class="tw-mt-6">
              Your previous subscription has been <strong>cancelled</strong>.
            </p>
            <p class="tw-mt-6">
              To access your previous invoices use the billing portal link above
              or upgrade using the button below.
            </p>
            <SpinnerButton
              :loading="loadingRenew"
              :spinner-only="true"
              type="button"
              data-cy="billing-renew"
              class="tw-mt-8"
              @click="renewSubscriptionCheckout"
            >
              Upgrade
            </SpinnerButton>
          </div>
        </div>
        <div
          v-if="
            !loading &&
              (isSubscribed ||
                (isCanceled && isInGracePeriod) ||
                hasPaymentIssue)
          "
          class="card tw-mt-10 tw-max-w-5xl"
          data-cy="billing-area"
        >
          <h3 class="sub-header tw-pb-5">Subscription</h3>
          <div>
            <div v-if="!isManuallyBilled">
              <TextWithIcon icon="clock-outline">
                Frequency: <strong>{{ frequency }}</strong>
              </TextWithIcon>
              <TextWithIcon icon="user-group">
                Employee limit: <strong>{{ planLimit }}</strong>
              </TextWithIcon>

              <div v-if="isCanceled && isInGracePeriod">
                <TextWithIcon icon="calendar">
                  Subscription end date:
                  <strong> {{ currentPeriodEndsAt }}</strong>
                </TextWithIcon>
              </div>
              <div v-else>
                <TextWithIcon icon="calendar">
                  Next billing date:
                  <strong> {{ invoiceDate }}</strong>
                </TextWithIcon>

                <TextWithIcon icon="credit-card">
                  Amount: <strong>{{ billingAmount }}</strong>
                  <span v-if="hasTax"> (excluding VAT)</span>
                </TextWithIcon>
              </div>
            </div>

            <div class="card tw-bg-gray-100 tw-mt-10">
              <h3 class="sub-header tw-pb-5">Usage</h3>

              <p class="tw-pb-2">{{ used }}/{{ planLimit }} employees</p>

              <BillingUsageChart :used="used" :limit="planLimit" />

              <p class="tw-pt-10">
                You have {{ planStatusText }}
                {{ frequency.toLocaleLowerCase() }} subscription for
                {{ planLimit }} employees.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="
          !isSubscriptionActive &&
            !isCanceled &&
            !isPreviousCustomer &&
            !hasPaymentIssue
        "
        id="pricing-table-container"
        ref="pricingTableContainer"
        class="tw-max-w-4xl tw-content-center"
      ></div>

      <div v-if="!loading">
        <hr class="tw-h-px tw-my-8 tw-bg-gray-300 tw-border-0" />
        <h3 class="sub-header tw-pb-5">
          Contact Support for Billing Assistance
        </h3>
        <p class="tw-max-w-2xl">
          Having billing queries? Click the button below to reach out to our
          support team. We're always ready to help clarify your concerns and
          provide prompt solutions.
        </p>
        <a
          href="https://help.leavedates.com/hc/en-us/requests/new"
          target="_blank"
          rel="noopener"
        >
          <SpinnerButton type="button" class="tw-mt-6">
            <SvgIcon name="mail" class="tw-mr-1 tw-w-5 tw-h-5" />
            Contact support
          </SpinnerButton>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ValidatesForm from '@/mixins/ValidatesForm'
import Subscription from '@/mixins/Subscription'
import SpinnerButton from '@/components/SpinnerButton'
import TextWithIcon from '@/components/billing/TextWithIcon'
import BillingUsageChart from '@/components/billing/BillingUsageChart'
import Billing from '@/api/billing/Billing'
import Loading from 'vue-loading-overlay'
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'Billing',

  components: {
    Loading,
    SpinnerButton,
    TextWithIcon,
    BillingUsageChart,
  },

  mixins: [ValidatesForm, Subscription, FormatDate],

  data: () => ({
    loading: false,
    loadingRenew: false,
    loadingPortal: false,
    planLimit: 25,
    hasTax: true,
    hasStripeId: false,
    used: 8,
    frequency: 'Monthly',
    nextBillingDate: '2023-11-01',
    billingAmount: '$944.00',
    planType: null,
    planTypes: {
      Free: 'free',
      Paid: 'paid',
      Cancelled: 'cancelled',
    },
  }),

  computed: {
    canShowPricingTable() {
      return (
        !this.isSubscriptionActive &&
        !this.isCanceled &&
        !this.isPreviousCustomer &&
        !this.hasPaymentIssue
      )
    },

    planStatusText() {
      if (this.isCanceled) return 'a cancelled'
      else if (this.hasPaymentIssue) return 'an overdue'
      else return 'an active'
    },

    invoiceDate() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.nextBillingDate
      )
    },

    isOnFreePlan() {
      return !this.isSubscriptionActive && this.planType === this.planTypes.Free
    },

    currentPeriodEndsAt() {
      if (!this.isSubscribed || (this.selectedPlan && !this.selectedPlan.name))
        return null

      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.endsAt,
        'utc'
      )
    },
  },

  metaInfo: {
    script: [
      {
        src: 'https://js.stripe.com/v3/pricing-table.js',
        async: true,
      },
    ],
  },

  async mounted() {
    await this.getBillingDetails()

    this.showPricingTable()
  },

  methods: {
    async redirectToBillingPortal() {
      this.loadingPortal = true
      const { data } = await Billing.getBillingPortal(this.$route.query)

      this.redirect(data.redirect_url)
      this.loadingPortal = false
    },

    async renewSubscriptionCheckout() {
      this.loadingRenew = true
      const { data } = await Billing.getRenewSubscription(this.$route.query)

      this.redirect(data.redirect_url)
      this.loadingRenew = false
    },

    redirect(url) {
      if (url) {
        window.open(url, '_self')
      }
    },

    async getBillingDetails() {
      this.loading = true
      try {
        const { data } = await Billing.getBillingDetails(this.$route.query)

        this.hasStripeId = data.has_stripe_id
        this.hasTax = data.has_tax
        this.planLimit = data.plan_limit
        this.used = data.plan_usage
        this.frequency = data.plan_interval === 'month' ? 'Monthly' : 'Annual'
        this.nextBillingDate = data.next_billing_date
        this.billingAmount = data.next_billing_value
        this.planType = data.plan_type
      } catch (e) {
        this.serverError()
      }
      this.loading = false
    },

    showPricingTable() {
      if (!this.canShowPricingTable) {
        return
      }

      let pricingTable = document.createElement('stripe-pricing-table')
      pricingTable.setAttribute(
        'pricing-table-id',
        process.env.VUE_APP_STRIPE_PRICING_TABLE_ID
      )
      pricingTable.setAttribute(
        'publishable-key',
        process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
      )
      pricingTable.setAttribute('client-reference-id', this.activeCompany.id)

      this.$refs.pricingTableContainer.replaceChildren(pricingTable)
    },
  },
}
</script>
