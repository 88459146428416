<template>
  <div
    class="allowance-input tw-mt-4 tw-pt-4 tw-pb-3 tw-border tw-border-blue-500 tw-rounded-lg"
  >
    <form class="tw-w-full" @submit.prevent="submit">
      <div class="tw-px-5 tw-pb-1">
        <div class="form-group">
          <div
            class="sm:tw-flex tw-w-full md:tw-space-x-4"
            data-cy="policy-form"
          >
            <div class="tw-flex-1">
              <label class="form-label" for="policy-name">
                Name
              </label>
              <input
                id="policy-name"
                v-model="breakdown.name"
                v-focus
                v-validate="'required|max:50'"
                data-vv-name="policy-name"
                data-vv-as="policy name"
                data-cy="policy-name"
                autocomplete="off"
                class="form-control tw-px-3 tw-py-2"
                type="text"
                @input="editBreakdown"
              />
              <span
                v-show="errors.has('name')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('name') }}
              </span>
            </div>
            <div class="tw-flex-1 policy-group tw-pt-3 md:tw-pt-0">
              <label class="form-label" for="policy">
                Policy
              </label>
              <VSelect
                id="policy"
                v-model="selectedPolicy"
                :options="selectablePolicies"
                :multiple="false"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                :disabled="isEditing"
                track-by="id"
                label="name"
                data-cy="policy-amount"
                placeholder=""
                @input="editBreakdown"
              >
              </VSelect>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div
              class="tw-w-1/2 tw-mr-4 tw-flex tw-items-center tw-text-sm"
              style="color: #8F9CB2;"
            >
              <div ref="defaultAllowanceReference" class="tw-flex tw-mr-2">
                <SvgIcon
                  name="info-circle"
                  class="svg-icon"
                  style="width: 10px; height: 10px;"
                />
              </div>
              {{
                selectedPolicy.id === 2
                  ? 'Maximum carry over'
                  : 'Default annual allowance'
              }}
              <div v-show="false" ref="defaultAllowanceTooltip">
                <div class="tw-p-4 tw-w-64">
                  Employees receive the default allowance when they are added to
                  Leave Dates.
                </div>
              </div>
            </div>
            <div class="tw-w-1/2">
              <div
                class="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg"
                style="max-height: 31px;"
              >
                <input
                  id="default-amount"
                  v-model="defaultAmountInDays"
                  v-validate="'required|decimal|min_value:0'"
                  data-vv-name="default-amount"
                  data-vv-as="default amount"
                  data-cy="default-amount"
                  class="form-control tw-px-3 tw-py-2 tw-w-1/3 tw-border-0"
                  type="number"
                  min="0"
                  step="any"
                  inputmode="decimal"
                  @input="editBreakdown"
                  @change="() => errors.clear()"
                />
                <div
                  class="tw-py-2 tw-w-2/3 tw-px-4 tw-border-l tw-border-gray-300 tw-bg-gray-200 tw-rounded-r-lg"
                >
                  {{ allowanceUnit }}
                  <span class="required-field">&#42;</span>
                </div>
              </div>

              <span
                v-show="
                  errors.has('default-amount') ||
                    errors.first('amount_in_minutes')
                "
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('default-amount') }}
                {{ errors.first('amount_in_minutes') }}
              </span>
            </div>
          </div>
        </div>

        <div v-show="selectedPolicy.id === 1" class="form-group">
          <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div
              class="tw-w-1/2 tw-mr-4 tw-flex tw-items-center tw-text-sm"
              style="color: #8F9CB2;"
            >
              <div
                ref="allowanceForNewCalendarReference"
                class="tw-flex tw-mr-2"
              >
                <SvgIcon
                  name="info-circle"
                  class="svg-icon"
                  style="width: 10px; height: 10px;"
                />
              </div>
              <div>
                Allowance for new calendars
              </div>
              <div v-show="false" ref="allowanceForNewCalendarTooltip">
                <div class="tw-p-4 tw-w-64">
                  <div>
                    This determines what happens to allowances when new
                    calendars are created.
                  </div>
                  <br />
                  <div>
                    <span class="tw-font-semibold"
                      >Copy from previous year</span
                    >
                    <div>
                      Employees receive the same allowance as year before.
                    </div>
                  </div>
                  <br />
                  <div>
                    <span class="tw-font-semibold"
                      >Reset to default allowance</span
                    >
                    <div>
                      Employees receive the default allowance for the company
                      ({{ defaultAmountInDays }}
                      <span class="tw-lowercase">{{
                        allowanceUnit | pluralize(defaultAmountInDays)
                      }}</span
                      >).
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-w-1/2">
              <div class="allowance-group tw-pt-3 md:tw-pt-0">
                <VSelect
                  id="allowance"
                  v-model="selectedAllocationType"
                  :options="selectableAllocationTypes"
                  :multiple="false"
                  :searchable="false"
                  :show-labels="false"
                  :allow-empty="false"
                  track-by="id"
                  label="name"
                  data-cy="new-calendar-policy"
                  placeholder=""
                  @input="editBreakdown"
                >
                </VSelect>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedPolicy.id === 2" class="form-group">
          <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div
              class="tw-w-1/2 tw-mr-4 tw-flex tw-items-center tw-text-sm"
              style="color: #8F9CB2;"
            >
              <svg-icon
                name="info-circle"
                class="svg-icon tw-mr-2"
                style="width: 10px; height: 10px;"
              />
              Expires After
            </div>
            <div class="tw-w-1/2">
              <div
                class="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-lg tw-border-r-0"
                style="max-height: 31px;"
              >
                <input
                  id="default-expiry-amount"
                  v-model="defaultExpiryTime"
                  v-validate="`${expirePeriodValidation}`"
                  data-vv-name="default-expiry-amount"
                  :disabled="selectedPolicyExpiryUnit.id === 0"
                  data-vv-as="default-expiry-amount"
                  class="form-control tw-px-3 tw-py-2 tw-w-1/3 tw-border-0"
                  type="number"
                  inputmode="numeric"
                  data-cy="expiry-amount"
                  @input="editBreakdown"
                />

                <div class="tw-w-2/3 policy-group policy-expiry-unit-group">
                  <VSelect
                    id="policyExpiryUnit"
                    v-model="selectedPolicyExpiryUnit"
                    :options="selectablePolicyExpiryUnits"
                    :multiple="false"
                    :searchable="false"
                    :show-labels="false"
                    :allow-empty="false"
                    track-by="id"
                    label="name"
                    data-cy="policy-expiry-unit"
                    placeholder=""
                    @input="editBreakdown"
                  >
                  </VSelect>
                </div>
              </div>

              <span
                v-show="errors.has('default-expiry-amount')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
                data-cy="expiry-period-error"
              >
                {{ expirePeriodErrorMessage }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-pt-3 tw-border-t tw-border-gray-300"></div>

      <div class="tw-px-5">
        <div class="tw-w-full tw-flex tw-items-center tw-justify-between">
          <SpinnerButton
            :disabled="!isEditing || deleting"
            :loading="deleting"
            :spinner-only="true"
            :spinner-classes="['tw-h-2 tw-w-2 tw-text-red-500']"
            as="link"
            theme="red"
            type="button"
            class="tw-font-medium"
            data-cy="btn-delete"
            @click="deleteBreakdown"
          >
            Delete
          </SpinnerButton>

          <div class="tw-flex tw-items-center tw-justify-between">
            <button
              type="button"
              class="btn btn-link tw-text-blue tw-px-0 tw-font-medium tw-mr-6"
              data-cy="btn-cancel-leave"
              @click.stop="cancel"
            >
              Cancel
            </button>

            <SpinnerButton
              :disabled="!valid || !editing || storing"
              :loading="storing"
              :spinner-only="true"
              class="submit-button"
              data-cy="btn-submit"
              type="submit"
            >
              {{ isEditing ? 'Update' : 'Add' }}
            </SpinnerButton>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { find } from 'lodash-es'
import VSelect from 'vue-multiselect'
import SpinnerButton from '@/components/SpinnerButton'
import FormatNumber from '@/mixins/FormatNumbers'
import ValidatesForm from '@/mixins/ValidatesForm'
import tippy from 'tippy.js'

export default {
  name: 'AllowanceBreakdownInput',

  components: {
    VSelect,
    SpinnerButton,
  },

  mixins: [ValidatesForm, FormatNumber],

  props: {
    dataBreakdown: {
      type: Object,
      required: true,
    },

    hasCarryOverAllowance: {
      type: Boolean,
      required: true,
    },

    storing: {
      type: Boolean,
      required: true,
    },

    deleting: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      breakdown: {
        id: this.dataBreakdown.id,
        name: this.dataBreakdown.name,
        allowance_type_id: this.dataBreakdown.allowance_type_id,
        minutes_per_working_day: this.dataBreakdown.minutes_per_working_day,
        amount_in_minutes: this.dataBreakdown.amount_in_minutes,
        type: this.dataBreakdown.policy_settings.type,
        expiry_duration: this.dataBreakdown.policy_settings.expiry_duration,
        expiry_duration_unit: this.dataBreakdown.policy_settings
          .expiry_duration_unit,
        allocation_type: this.dataBreakdown.policy_settings.allocation_type,
      },
      editing: false,
    }
  },

  computed: {
    allowanceUnit() {
      return this.activeCompany.allowance_unit_is_days ? 'Days' : 'Hours'
    },

    isEditing() {
      return !!this.dataBreakdown.id
    },

    policyTypes() {
      return [
        {
          id: 1,
          name: 'Set amount per year',
        },
        {
          id: 2,
          name: 'Carry over unused allowance',
        },
      ]
    },

    selectablePolicies() {
      if (
        this.hasCarryOverAllowance &&
        this.dataBreakdown.policy_settings.type === 1
      ) {
        return this.policyTypes.filter(policy => policy.id !== 2)
      }
      return this.policyTypes
    },

    selectablePolicyExpiryUnits() {
      return [
        {
          id: 1,
          key: 'days',
          name: 'Days',
        },
        {
          id: 2,
          key: 'months',
          name: 'Months',
        },
        {
          id: 0,
          key: 'never-expire',
          name: 'Never Expire',
        },
      ]
    },

    selectedPolicy: {
      get() {
        return find(this.policyTypes, {
          id: this.breakdown.type,
        })
      },
      set(val) {
        if (!this.isEditing && val.id === 2) {
          this.breakdown.amount_in_minutes =
            5 * this.dataBreakdown.minutes_per_working_day
          this.breakdown.expiry_duration = 3
          this.breakdown.expiry_duration_unit = 2
        }

        if (val.id === 2) {
          this.allowanceForNewCalendarTippy.disable()
          this.defaultAllowanceTippy.disable()
        } else {
          this.allowanceForNewCalendarTippy.enable()
          this.defaultAllowanceTippy.enable()
        }

        this.breakdown.type = val.id
      },
    },

    selectedPolicyExpiryUnit: {
      get() {
        return find(this.selectablePolicyExpiryUnits, {
          id: this.breakdown.expiry_duration_unit,
        })
      },
      set(val) {
        this.breakdown.expiry_duration_unit = val.id
      },
    },

    defaultAmountInDays: {
      get() {
        const defaultAmount = this.activeCompany.allowance_unit_is_days
          ? this.breakdown.amount_in_minutes /
            this.breakdown.minutes_per_working_day
          : this.breakdown.amount_in_minutes / 60

        return this.toFixed(defaultAmount, 2)
      },
      set(value) {
        this.breakdown.minutes_per_working_day = this.activeCompany.minutes_per_working_day

        this.breakdown.amount_in_minutes =
          value *
          (this.activeCompany.allowance_unit_is_days
            ? this.breakdown.minutes_per_working_day
            : 60)
      },
    },

    defaultExpiryTime: {
      get() {
        return this.breakdown.expiry_duration
      },
      set(value) {
        this.breakdown.expiry_duration = value
      },
    },

    expirePeriodValidation() {
      if (this.selectedPolicyExpiryUnit.id === 1) {
        return 'required|numeric|min_value:1|max_value:365'
      }
      if (this.selectedPolicyExpiryUnit.id === 2) {
        return 'required|numeric|min_value:1|max_value:12'
      }

      return ''
    },

    expirePeriodErrorMessage() {
      if (this.selectedPolicyExpiryUnit.id === 2) {
        return 'The expiry period must be between 1 and 12 months'
      }

      return 'The expiry period must be between 1 and 365 days'
    },

    selectableAllocationTypes() {
      return [
        {
          id: 1,
          name: 'Copy from previous year',
        },
        {
          id: 2,
          name: 'Reset to default allowance',
        },
      ]
    },

    selectedAllocationType: {
      get() {
        return find(this.selectableAllocationTypes, {
          id: this.breakdown.allocation_type,
        })
      },
      set(val) {
        this.breakdown.allocation_type = val.id
      },
    },
  },

  mounted() {
    this.defaultAllowanceTippy = tippy.one(
      this.$refs.defaultAllowanceReference,
      {
        html: this.$refs.defaultAllowanceTooltip.firstChild,
        trigger: 'mouseenter',
        position: 'bottom',
        interactive: true,
        theme: 'dark',
        animation: 'scale',
        inertia: true,
        arrow: true,
      }
    )

    this.allowanceForNewCalendarTippy = tippy.one(
      this.$refs.allowanceForNewCalendarReference,
      {
        html: this.$refs.allowanceForNewCalendarTooltip.firstChild,
        trigger: 'mouseenter',
        position: 'top',
        interactive: true,
        theme: 'dark',
        animation: 'scale',
        inertia: true,
        arrow: true,
      }
    )

    if (this.selectedPolicy.id === 2) {
      this.allowanceForNewCalendarTippy.disable()
      this.defaultAllowanceTippy.disable()
    }

    this.$once('hook:beforeDestroy', () => {
      this.defaultAllowanceTippy.destroy()
      this.allowanceForNewCalendarTippy.destroy()
    })
  },

  methods: {
    async submit() {
      const valid = await this.validate()

      if (!valid) return

      const breakdown = {
        ...this.breakdown,
        expiry_duration_unit: this.selectedPolicyExpiryUnit.key,
      }

      if (this.isEditing) {
        if (this.breakdown.type === 2) {
          this.breakdown.allocation_type = 1
          breakdown.allocation_type = 1
        }

        this.$emit('edit-breakdown', breakdown)
      } else {
        this.$emit('add-breakdown', breakdown)
      }

      this.editing = false
    },

    cancel() {
      this.reset()

      this.$emit('cancel')
    },

    async deleteBreakdown() {
      this.$emit('drop-breakdown', this.breakdown)
    },

    reset() {
      this.breakdown.id = this.dataBreakdown.id
      this.breakdown.name = this.dataBreakdown.name
      this.breakdown.allowance_type_id = this.dataBreakdown.allowance_type_id
      this.breakdown.minutes_per_working_day = this.dataBreakdown.minutes_per_working_day
      this.breakdown.amount_in_minutes = this.dataBreakdown.amount_in_minutes
      this.breakdown.type = this.dataBreakdown.policy_settings.type
      this.breakdown.allocation_type = this.dataBreakdown.allocation_type

      this.editing = false
      this.$validator.errors.clear()
    },

    editBreakdown() {
      this.errors.clear()
      this.editing = true
      this.$emit('breakdown-editing')
    },
  },
}
</script>

<style>
.policy-group .multiselect,
.policy-group .multiselect__tags,
.allowance-group .multiselect,
.allowance-group .multiselect__tags {
  min-height: 31px;
  max-height: 31px;
}

.policy-group .multiselect__select,
.allowance-group .multiselect__select {
  height: 31px;
}

.policy-group .multiselect__input,
.policy-group .multiselect__single,
.allowance-group .multiselect__input,
.allowance-group .multiselect__single {
  height: 31px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.policy-group .multiselect__option {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.policy-expiry-unit-group .multiselect__tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.allowance-input .form-group {
  margin-bottom: 1rem;
}

.allowance-input .form-label {
  font-size: 9px;
  color: #8f9cb2;
}

.allowance-input .submit-button {
  padding: 9px 24px;
}
</style>
