<template>
  <div>
    <form class="tw-w-full">
      <div class="form-group">
        <div class="tw-w-full" data-cy="toil-instructions">
          <p class="tw-mb-6">
            If you offer time off in lieu to your team, then select which
            allowance you wish this to apply to:
          </p>
        </div>

        <div class="tw-w-full xl:tw-w-1/3">
          <div class="tw-relative">
            <UpgradePlanPopup
              action-message="enable/disable overtime"
              :feature="Feature.TimeOffInLieu"
              :is-active="!companyHasToilFeature"
            >
              <vSelect
                id="report-type"
                :value="selectedAllowanceType"
                :options="selectableAllowanceTypes"
                :multiple="false"
                :searchable="false"
                :show-labels="false"
                :allow-empty="false"
                :max-height="160"
                :disabled="!companyHasToilFeature"
                track-by="id"
                label="name"
                data-cy="toil-allowance"
                placeholder=""
                @input="switchTimeOffInLieu"
              >
              </vSelect>
            </UpgradePlanPopup>
          </div>
        </div>
      </div>

      <template v-if="currentTimeOffInLieu && visibilitySettings">
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label">
              Title
            </label>

            <input
              v-model="visibilitySettings.title"
              v-validate="'required'"
              v-focus
              name="title"
              class="form-control"
              type="text"
              autocomplete="off"
              data-cy="overtime-title-text"
            />

            <p
              v-show="errors.has('title')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('title') }}
            </p>
          </div>
        </div>
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label" for="colour">
              Display Colour <span class="required-field">&#42;</span>
            </label>

            <ColorPicker
              v-if="visibilitySettings.color"
              id="colour"
              :value="visibilitySettings.color"
              :palette="colorBucket"
              @input="item => (visibilitySettings.color = item.hex)"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label">
              Options
            </label>

            <div class="tw-flex tw-items-center tw-leading-none">
              <ToggleButton
                v-model="toilDetailsPublicVisibility"
                :labels="true"
                color="#1da1f2"
                data-cy="btn-display-toil-on-wallchart"
              />

              <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
                <span>
                  The amount and the reason should be shown publicly on the Wall
                  Chart
                </span>

                <ExtraInfo icon="question" class="tw-inline-block tw--mt-1">
                  <div class="tw-p-4 tw-w-48">
                    If this setting is 'Off' then the Time off in Lieu amount
                    and reason will only be shown to Admins, Approvers and the
                    person who earned it. If it is 'On' then it will be shown to
                    to everyone.
                  </div>
                </ExtraInfo>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              v-model="toilPublicVisibility"
              :sync="true"
              :labels="true"
              color="#1da1f2"
              data-cy="btn-hide-toil-on-wallchart"
            />

            <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
              <span class="tw-mr-1" data-cy="enable-toil-label"
                >Hide TOIL from the wall chart (except for approvers and admin
                users)
              </span>
            </label>
          </div>
        </div>

        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              v-model="allowEmployeesToRequestOvertime"
              :labels="true"
              color="#1da1f2"
              data-cy="btn-allow-emp-to-request-toil"
            />

            <label class="tw-ml-2 tw-select-none tw-flex tw-items-center">
              <span class="tw-mr-1">Allow employees to request TOIL.</span>
            </label>
          </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-mb-3">
          <div class="tw-w-full">
            <UpgradePlanPopup
              action-message="enable overtime"
              :feature="Feature.TimeOffInLieu"
              :is-active="!companyHasToilFeature"
            >
              <button
                :disabled="!companyHasToilFeature || !valid || loading"
                class="btn btn-blue tw-rounded-lg"
                data-cy="btn-save-overtime-display-settings"
                type="submit"
                @click="saveForm"
              >
                Save
              </button>
            </UpgradePlanPopup>
          </div>
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Compact } from 'vue-color'
import moment from 'moment-timezone'
import vSelect from 'vue-multiselect'
import { find, sortBy } from 'lodash-es'
import { ToggleButton } from 'vue-js-toggle-button'
import ValidatesForm from '@/mixins/ValidatesForm'
import { Calendars, Company } from '@/api'
import ToilVisibilitySettings from '@/api/company/ToilVisibilitySettings'
import Feature from '@/models/Billing/Feature'
import UpgradePlanPopup from '@/components/UpgradePlanPopup'
import TimeOffInLieu from '@/api/company/TimeOffInLieu'
import CalendarCollection from '@/models/company/CalendarCollection'

const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'TimeOffInLieu',

  components: {
    UpgradePlanPopup,
    vSelect,
    ExtraInfo,
    ToggleButton,
    ColorPicker: Compact,
  },

  mixins: [ValidatesForm],

  props: {
    allowanceTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      calendars: new CalendarCollection(),
      visibilitySettings: null,
      currentTimeOffInLieu: null,
      allowEmployeesToRequestOvertime: null,
      colorBucket: [
        '#F05451',
        '#ff9f43',
        '#fcf600',
        '#1cd1a1',
        '#00a3a4',
        '#4ddbfb',
        '#35aaf0',
        '#1561bd',
        '#5f27cd',
        '#9f7de1',
        '#f183da',
        '#576574',
        '#f8baba',
        '#ffd9b4',
        '#fefb99',
        '#d3f5d6',
        '#99dadb',
        '#b8f1fd',
        '#aeddf9',
        '#a1c0e5',
        '#bfa9eb',
        '#d9cbf3',
        '#fba7e9',
        '#bcc1c7',
      ],
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    companyHasToilFeature() {
      return this.activeCompany.hasFeature(Feature.TimeOffInLieu)
    },

    defaultCalendar() {
      return (
        this.calendars.findCalendarForYear(moment().utc(true)) ??
        this.calendars.first()
      )
    },

    selectedAllowanceType() {
      let allowanceTypeId = ''

      if (this.currentTimeOffInLieu) {
        allowanceTypeId = this.currentTimeOffInLieu.allowance_type_id
      }

      return find(this.selectableAllowanceTypes, { id: allowanceTypeId })
    },

    sortedAllowanceTypes() {
      return sortBy(this.allowanceTypes, 'name')
    },

    selectableAllowanceTypes() {
      return [
        {
          id: '',
          name: 'Not offered',
        },
        ...this.sortedAllowanceTypes,
      ]
    },

    toilDetailsPublicVisibility: {
      get() {
        return !this.visibilitySettings.is_private
      },
      set(isPublic) {
        this.visibilitySettings.is_private = !isPublic
      },
    },

    toilPublicVisibility: {
      get() {
        return !this.visibilitySettings.is_publicly_visible
      },
      set(isPrivate) {
        this.visibilitySettings.is_publicly_visible = !isPrivate
      },
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      async handler(newVal, oldVal) {
        if (newVal === oldVal) return

        await this.fetchCalendars()
        await this.fetchCurrentTimeOffInLieu()
      },
    },

    activeCompany: {
      deep: true,
      immediate: true,
      handler(company) {
        this.visibilitySettings = {
          ...company.time_off_in_lieu_visibility_settings,
        }

        this.allowEmployeesToRequestOvertime =
          company.allow_employees_to_request_overtime
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser', 'fetchCompany']),

    async switchTimeOffInLieu(allowanceType) {
      this.loading = true

      if (this.currentTimeOffInLieu == null) {
        await this.storeTimeOffInLieu(allowanceType.id)
      } else if (allowanceType.id === '') {
        await this.deleteTimeOffInLieu()
      } else {
        await this.updateTimeOffInLieu(allowanceType.id)
      }

      await this.fetchCurrentTimeOffInLieu()

      this.fetchUser()

      this.fetchCompany(this.activeCompany)

      this.loading = false
    },

    async storeTimeOffInLieu(allowanceTypeId) {
      await TimeOffInLieu.offer({
        company_id: this.activeCompany.id,
        allowance_type_id: allowanceTypeId,
      })

      this.success('Time off in lieu activated successfully.')
    },

    async updateTimeOffInLieu(allowanceTypeId) {
      await TimeOffInLieu.update(this.currentTimeOffInLieu, {
        company_id: this.activeCompany.id,
        allowance_type_id: allowanceTypeId,
      })

      this.success('Time off in lieu activated successfully.')
    },

    async deleteTimeOffInLieu() {
      const confirmed = await this.confirm(
        'Are you sure you want to turn this off? Any existing time off in lieu will be deleted.',
        'Turn off TOIL'
      )

      if (!confirmed) return

      await TimeOffInLieu.notOffer(this.currentTimeOffInLieu, {
        company_id: this.activeCompany.id,
      })

      this.success('Time off in lieu disabled successfully.')
    },

    async fetchCurrentTimeOffInLieu() {
      this.loading = true

      try {
        this.currentTimeOffInLieu = await TimeOffInLieu.get({
          company_id: this.activeCompany.id,
          calendar: this.defaultCalendar.id,
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchCalendars() {
      this.loading = true

      try {
        this.calendars = await Calendars.all({
          company_id: this.activeCompany.id,
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async saveForm() {
      this.loading = true

      await Promise.all([
        this.updateVisibilitySettings(),
        this.enableEmployeesToRequestOvertime(),
      ])

      this.success('Time off in Lieu configuration updated.')

      this.fetchUser()
      this.fetchCompany(this.activeCompany)

      this.loading = false
    },

    async updateVisibilitySettings() {
      try {
        await ToilVisibilitySettings.update(this.visibilitySettings)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async enableEmployeesToRequestOvertime() {
      try {
        await Company.update(this.activeCompany, {
          allow_employees_to_request_overtime: this
            .allowEmployeesToRequestOvertime,
        })
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }
    },
  },
}
</script>
