<template>
  <div />
</template>

<script>
import { Billing } from '@/api'
import {
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_UNPROCESSABLE,
} from '@/plugins/http'

export default {
  name: 'ConnectSubscription',

  middleware: 'auth',

  layout: 'DefaultLayout',

  async created() {
    try {
      const subscription = this.$route.params.subscription
      const company = this.$route.params.company

      await Billing.connectSubscriptionToCompany(subscription, company)

      this.success('Subscription connected to the company.')
    } catch ({ response }) {
      if (response.status === HTTP_STATUS_UNPROCESSABLE) {
        if (response.data.errors.hasOwnProperty('company')) {
          this.showError(response.data.errors.company[0])
        }

        if (response.data.errors.hasOwnProperty('subscription')) {
          this.showError(response.data.errors.subscription[0])
        }
      }

      if (response.status === HTTP_STATUS_UNAUTHORIZED) {
        this.showError("You don't have permission to perform this action.")
      }
    }

    this.$router.push({ name: 'home' }, () => {})
  },

  methods: {
    showError(message) {
      this.error(message, 'Error')
    },
  },
}
</script>
