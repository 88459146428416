<template>
  <div class="tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 xl:tw-w-1/4 tw-px-2">
    <div
      class="tw-mb-4 tw-p-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden my-leave-calendar"
    >
      <div
        class="tw-mb-2 tw-pt-1 tw-px-2 tw-text-xl tw-font-semibold"
        v-text="month.format('MMMM')"
      />
      <FullCalendar
        v-if="employee"
        ref="calendar"
        :events="calendarEvents"
        :config="calendarConfig"
        :selectable="hasProperSubscription"
        default-view="dayGridMonth"
        class="leave-calendar"
        :class="{
          'high-contrast-mode': authUser.settings.high_contrast_mode,
        }"
        @event-created="createRequest"
        @show-overtime="overtime => this.$emit('show-overtime', overtime)"
        @show-leave="leave => this.$emit('show-leave', leave)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FullCalendar from '@/components/FullCalendar.vue'
import FormatDate from '@/mixins/FormatDate'
import Subscription from '@/mixins/Subscription'
import dayGridPlugin from '@fullcalendar/daygrid'
import EmploymentSchedule from '@/models/employment/EmploymentSchedule'

export default {
  name: 'MyLeaveCalendar',

  components: { FullCalendar },

  mixins: [Subscription, FormatDate],

  props: {
    month: {
      type: Object,
      required: true,
    },

    monthNumber: {
      type: Number,
      required: true,
    },

    employee: {
      type: Object,
      required: true,
    },

    employmentSchedule: {
      type: EmploymentSchedule,
      required: true,
    },
  },

  computed: {
    calendarEvents() {
      return [
        ...this.leaves,
        ...this.overtimes,
        ...this.holidays,
        ...this.leaveLimits,
        ...this.birthday,
      ]
    },

    leaves() {
      return this.employmentSchedule.findLeave(this.validRange).map(leave => {
        return {
          ...leave,
          eventType: 'leave',
          start: this.getRequestStartTime(leave),
          end: this.getRequestEndTime(leave),
        }
      })
    },

    overtimes() {
      return this.employmentSchedule
        .findOvertime(this.validRange)
        .map(overtime => {
          return {
            ...overtime,
            eventType: 'overtime',
            start: this.getRequestStartTime(overtime),
            end: this.getRequestEndTime(overtime),
          }
        })
    },

    holidays() {
      let groupedHolidays = this.employmentSchedule.findGroupedHolidays(
        this.validRange
      )

      return Object.values(groupedHolidays).map(holidays => {
        const holiday = holidays[0]

        const typeClass =
          holiday.type === 'non_working_day'
            ? 'event-holiday--non-working-day'
            : 'event-holiday--working-day'

        return {
          classNames: ['event-holiday', typeClass],
          eventType: 'holiday',
          date: holiday.date.format('YYYY-MM-DD'),
          display: 'background',
          type: holiday.type,
          events: holidays,
        }
      })
    },

    birthday() {
      if (this.employmentSchedule.isBirthdayWithinPeriod(this.validRange)) {
        return [
          {
            classNames: ['event-birthday', 'my-leave-birthday-event'],
            eventType: 'birthday',
            date: this.employmentSchedule.dateOfBirth.format('YYYY-MM-DD'),
            employmentName: this.employee.full_name,
            display: 'background',
          },
        ]
      }

      return []
    },

    leaveLimits() {
      return this.employmentSchedule
        .findLeaveLimits(this.validRange)
        .map(limit => {
          return {
            classNames: ['event-leave-limit-exceeded'],
            eventType: 'leave-limit',
            start: limit.start.format('YYYY-MM-DD'),
            end: limit.end.format('YYYY-MM-DD'),
            display: 'background',
          }
        })
    },

    calendarConfig() {
      return {
        contentHeight: 334,
        eventOverlap: false,
        fixedWeekCount: false,
        plugins: [dayGridPlugin],
        showNonCurrentDates: false,
        weekNumberCalculation: 'iso',
        initialDate: this.toDateTimeString(this.month),
        firstDay: this.activeCompany.first_day_of_week,
        businessHours: this.businessHoursOfEmployment(this.employee),
        validRange: this.validRange,
        headerToolbar: false,
        eventDisplay: 'block',
      }
    },

    validRange() {
      let start = this.month
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD')
      let end = this.month
        .clone()
        .endOf('month')
        .add(1, 'day')
        .format('YYYY-MM-DD')

      if (this.isFirstMonth) {
        start = this.startDate.format('YYYY-MM-DD')
      } else if (this.isLastMonth) {
        end = this.endDate.format('YYYY-MM-DD')
      }

      return { start, end }
    },

    employmentStartDateIsNotMonthStart() {
      return (
        this.employee.start_date &&
        this.employee.align_allowance_to_start_date &&
        moment.utc(this.employee.start_date).date() !== 1
      )
    },

    startDate() {
      if (this.employmentStartDateIsNotMonthStart) {
        return moment.utc(this.employee.start_date).year(this.month.year())
      }

      return this.month.clone().startOf('month')
    },

    endDate() {
      if (this.employmentStartDateIsNotMonthStart) {
        return moment.utc(this.employee.start_date).year(this.month.year())
      }

      return this.month
        .clone()
        .endOf('month')
        .add(1, 'day')
    },

    isFirstMonth() {
      return this.monthNumber === 1
    },

    isLastMonth() {
      return this.monthNumber === 13
    },
  },

  watch: {
    month(val) {
      this.$refs.calendar.fireMethod('gotoDate', this.toDateTimeString(val))
    },

    employee() {
      this.$refs.calendar.fireMethod(
        'setOption',
        'businessHours',
        this.businessHoursOfEmployment(this.employee)
      )

      this.$refs.calendar.fireMethod('setOption', 'validRange', this.validRange)
    },
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    getRequestStartTime(request) {
      return request.start
        .clone()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    getRequestEndTime(request) {
      return request.end
        .clone()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
    },

    destroy() {
      this.$refs.calendar.fireMethod('destroy')
    },

    businessHoursOfEmployment(employment) {
      return employment.available_working_schedule.schedule_breakdowns.map(
        breakdown => ({
          ...breakdown,
          daysOfWeek: [
            breakdown.iso_week_day === 7 ? 0 : breakdown.iso_week_day,
          ],
          startTime: breakdown.start_time,
          endTime: breakdown.end_time,
        })
      )
    },

    createRequest(e) {
      this.hideCalendarPoppers()

      this.$emit('create-request', e)
    },

    hideCalendarPoppers() {
      for (const popper of document.querySelectorAll('.tippy-popper')) {
        const instance = popper._tippy

        if (instance.state.visible) {
          instance.popperInstance.disableEventListeners()
          instance.hide()
        }
      }
    },
  },
}
</script>
