<template>
  <div />
</template>

<script>
import Company from '@/api/company/Company.js'
import Http from '@/plugins/http.js'

export default {
  name: 'VerifyDeleteCompany',

  layout: 'DefaultLayout',

  data: () => ({
    ownCompanies: [],
  }),

  async created() {
    try {
      await Company.delete(this.$route.fullPath)

      this.success('Company deleted successfully.')

      let routeName = 'signin'

      if (this.activeEmployment) {
        await this.fetchCompanies()

        routeName = this.hasNoCompany() ? 'company-deleted' : 'wall-chart'
      }

      if (routeName === 'company-deleted') {
        await Http.post('logout')
      }

      this.$router.replace({ name: routeName }, () => {})
    } catch ({ response }) {
      if (response.status === 410 || response.status === 403) {
        this.error(response.data.message)
      }

      this.$router.replace({ name: 'signin' }, () => {})
    }
  },

  methods: {
    async fetchCompanies() {
      const { data } = await Company.all()

      this.ownCompanies = data
    },
    hasNoCompany() {
      return this.ownCompanies.length === 0
    },
  },
}
</script>
